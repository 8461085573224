import React from 'react';
import { Typography, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
	COMPANY_NAME_LCC,
	COMPANY_ADDRESS,
	COMPANY_PHONE,
	COMPANY_EMAIL,
} from '../common/data';

const ContactContainer = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(4),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	maxWidth: 600,
	width: '100%',
	boxSizing: 'border-box',
}));

const ContactTitle = styled(Typography)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

const ContactSection = styled(Box)(({ theme }) => ({
	marginTop: theme.spacing(4),
	width: '100%',
}));

const HighlightedText = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	color: theme.palette.primary.main,
}));

const PageContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100vh',
	bgcolor: theme.palette.background.default,
}));

const ContactsPage: React.FC = () => {
	return (
		<PageContainer>
			<ContactContainer elevation={3}>
				<ContactTitle variant='h4'>Contact Us</ContactTitle>

				<ContactSection>
					<Typography variant='h6'>Company Information</Typography>
					<Typography variant='body1'>
						Company Name:
						<HighlightedText>{COMPANY_NAME_LCC}</HighlightedText>
					</Typography>
					<Typography variant='body1'>
						Address:
						<HighlightedText>{COMPANY_ADDRESS}</HighlightedText>
					</Typography>
					<Typography variant='body1'>
						Phone: <HighlightedText>{COMPANY_PHONE}</HighlightedText>
					</Typography>
					<Typography variant='body1'>
						Email: <HighlightedText>{COMPANY_EMAIL}</HighlightedText>
					</Typography>
				</ContactSection>
			</ContactContainer>
		</PageContainer>
	);
};
export default ContactsPage;
