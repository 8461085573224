import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	List,
	ListItem,
	ListItemText,
	Divider,
	IconButton,
	ListItemIcon,
} from '@mui/material';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { styled } from '@mui/material/styles';

import { SIDEBAR_ITEMS } from '../common/data';

enum IconEnum {
	Home = 'home',
	PrivacyPolicy = 'privacy-policy',
	Contacts = 'contacts',
}

const iconMap: { [key in IconEnum]: React.ReactNode } = {
	[IconEnum.Home]: <HomeOutlinedIcon />,
	[IconEnum.PrivacyPolicy]: <HandshakeOutlinedIcon />,
	[IconEnum.Contacts]: <ContactMailOutlinedIcon />,
};

function getIcon(iconName: IconEnum): React.ReactNode {
	return iconMap[iconName];
}

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

const Sidebar = styled('div')(({ theme }) => ({
	width: 240,
	height: '100vh',
	position: 'absolute',
	top: 0,
	left: 0,
	zIndex: theme.zIndex.drawer,
	backgroundColor: theme.palette.background.paper,
	boxShadow: theme.shadows[5],
	transform: 'translateX(-100%)',
	transition: theme.transitions.create('transform', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	'&.open': {
		transform: 'translateX(0)',
	},
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
	textDecoration: 'none',
	color: theme.palette.text.primary,
	display: 'flex',
	alignItems: 'center',
	'&.active': {
		fontWeight: 'bold',
	},
}));

interface DrawerComponentProps {
	open: boolean;
	toggleDrawer: () => void;
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({
	open,
	toggleDrawer,
}) => (
	<Sidebar className={open ? 'open' : ''}>
		<DrawerHeader>
			<IconButton onClick={toggleDrawer} />
		</DrawerHeader>

		<Divider />

		<List>
			{SIDEBAR_ITEMS.map(({ text, name }, index) => (
				<ListItem key={name}>
					<StyledNavLink to={SIDEBAR_ITEMS[index].route} onClick={toggleDrawer}>
						<ListItemIcon>
							{getIcon(IconEnum[name as keyof typeof IconEnum])}
						</ListItemIcon>
						<ListItemText primary={text} />
					</StyledNavLink>
				</ListItem>
			))}
		</List>
	</Sidebar>
);

export default DrawerComponent;
