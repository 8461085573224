import React from 'react';
import { Typography, Box } from '@mui/material';

import { COMPANY_NAME } from '../common/data';

const HomePage: React.FC = () => (
	<Box
		sx={{
			height: '100vh',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		}}>
		<Typography
			variant='h2'
			component='div'
			align='center'
			sx={{ fontWeight: 'bold', color: 'primary.main' }}>
			{COMPANY_NAME}
		</Typography>
	</Box>
);

export default HomePage;
