import React, { useState, useEffect, ReactNode, useRef } from 'react';
import { AppBar, styled } from '@mui/material';

import { AppBarComponent, DrawerComponent } from './components';

const LayoutContainer = styled('div')({
	display: 'flex',
	height: '100vh',
	maxHeight: '100vh',
	width: '100%',
	overflow: 'hidden',
});

const AppBarStyled = styled(AppBar)(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1,
}));

const Main = styled('main', {
	shouldForwardProp: (prop) => prop !== 'headerHeight',
})<{ headerHeight: number }>(({ theme, headerHeight }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	marginTop: headerHeight,
	transition: theme.transitions.create(['margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
}));

const Overlay = styled('div')<{ open: boolean }>(({ theme, open }) => ({
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.5)',
	zIndex: theme.zIndex.drawer - 1,
	display: open ? 'block' : 'none',
}));

interface LayoutProps {
	children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const [open, setOpen] = useState<boolean>(false);
	const [headerHeight, setHeaderHeight] = useState(0);
	const headerRef = useRef<HTMLDivElement>(null);

	const toggleDrawer = () => {
		setOpen(!open);
	};

	useEffect(() => {
		if (headerRef.current) {
			setHeaderHeight(headerRef.current.clientHeight);
		}
	}, [headerRef.current]);

	return (
		<LayoutContainer>
			<AppBarStyled position='fixed' ref={headerRef}>
				<AppBarComponent onMenuClick={toggleDrawer} />
			</AppBarStyled>

			<DrawerComponent open={open} toggleDrawer={toggleDrawer} />
			<Overlay open={open} />

			<Main headerHeight={headerHeight}>{children}</Main>
		</LayoutContainer>
	);
};

export default Layout;
