import React from 'react';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import CustomIcon from './CustomIcon';

interface AppBarComponentProps {
	onMenuClick: () => void;
}

const AppBarComponent: React.FC<AppBarComponentProps> = ({ onMenuClick }) => (
	<AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
		<Toolbar sx={{ justifyContent: 'space-between' }}>
			<IconButton color='inherit' edge='start' onClick={onMenuClick}>
				<MenuIcon />
			</IconButton>

			<IconButton color='inherit' edge='start'>
				<CustomIcon />
			</IconButton>
		</Toolbar>
	</AppBar>
);

export default AppBarComponent;
