import React from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';
import styled from '@emotion/styled';

import Layout from './Layout';
import HomePage from './pages/HomePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ContactsPage from './pages/ContactsPage';

const AppContainer = styled('div')({
	height: '100vh',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	background:
		'linear-gradient(45deg,rgba(255, 255, 255, 1) 0%,rgba(245, 245, 245, 1) 25%,rgba(255, 255, 255, 1) 50%,rgba(245, 245, 245, 1) 75%,rgba(255, 255, 254, 1) 100%)',
	backgroundSize: '200% 200%',
});

const App: React.FC = () => {
	return (
		<AppContainer>
			<Router>
				<Layout>
					<Routes>
						<Route path='/' element={<HomePage />} />
						<Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
						<Route path='/contacts' element={<ContactsPage />} />
						<Route path='*' element={<Navigate to='/' replace />} />
					</Routes>
				</Layout>
			</Router>
		</AppContainer>
	);
};

export default App;
