import React from 'react';
import { SvgIcon } from '@mui/material';

const CustomIcon = () => (
	<SvgIcon viewBox='0 0 26 26'>
		<svg
			version='1.0'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 2600.000000 2600.000000'
			preserveAspectRatio='xMidYMid meet'>
			<metadata></metadata>
			<g
				transform='translate(0.000000,2600.000000) scale(0.100000,-0.100000)'
				fill='#ffffff'
				stroke='none'>
				<path
					d='M16770 21553 c-1818 -592 -3306 -1077 -3308 -1078 -1 -1 63 -2195
            143 -4876 80 -2681 145 -4903 145 -4939 l-1 -65 -147 -127 c-1464 -1262 -3573
            -2367 -5027 -2633 -316 -58 -439 -69 -755 -70 -266 0 -308 2 -425 23 -418 75
            -688 249 -832 537 -33 65 -66 165 -78 232 l-6 41 88 6 c413 30 924 144 1468
            329 374 127 607 221 927 374 871 415 1413 874 1598 1355 29 75 77 268 91 366
            7 48 10 138 7 210 -4 109 -10 141 -37 222 -51 151 -125 257 -236 335 -277 198
            -683 233 -1336 115 -564 -102 -992 -268 -1419 -553 -595 -396 -1086 -965
            -1361 -1578 -73 -161 -153 -409 -185 -574 -15 -77 -29 -142 -30 -144 -6 -8
            -261 63 -341 95 -450 180 -745 534 -819 983 -22 137 -15 350 16 466 57 214
            179 385 331 461 85 43 143 54 290 54 123 0 140 2 180 23 66 35 102 93 107 171
            5 71 -10 109 -61 164 -76 80 -339 92 -552 25 -369 -116 -623 -422 -721 -873
            -15 -69 -19 -127 -19 -305 1 -205 3 -228 29 -339 76 -323 208 -571 425 -798
            251 -263 598 -444 1016 -528 61 -12 113 -27 117 -34 4 -6 8 -24 8 -41 0 -46
            36 -204 65 -291 123 -368 387 -641 767 -798 707 -291 1811 -178 3098 319 1377
            532 2925 1473 3994 2429 117 105 135 124 157 170 11 23 968 682 4069 2803
            3149 2153 4054 2776 4052 2790 -3 13 -1917 5901 -2128 6546 -19 56 -30 77 -42
            76 -9 0 -1504 -485 -3322 -1076z m2828 509 c-20 -52 -1834 -3602 -1838 -3600
            -79 33 -3598 1834 -3593 1839 8 7 5411 1768 5426 1769 5 0 7 -4 5 -8z m1248
            -2927 c480 -1474 871 -2681 870 -2682 -2 -2 -3598 1825 -3605 1832 -2 2 410
            817 915 1810 l919 1805 14 -42 c8 -24 407 -1249 887 -2723z m-5130 -61 c1022
            -520 1862 -949 1867 -953 6 -6 -3440 -6798 -3462 -6823 -4 -4 -65 1957 -136
            4357 -72 2401 -130 4365 -129 4365 1 0 838 -426 1860 -946z m4382 -2229 c864
            -440 1568 -803 1564 -806 -19 -17 -7195 -4920 -7197 -4917 -2 2 778 1538 1732
            3413 l1735 3410 297 -150 c164 -82 1005 -510 1869 -950z m-10158 -5310 c145
            -31 239 -93 277 -183 37 -90 25 -294 -29 -477 -76 -260 -322 -533 -723 -804
            -659 -446 -1727 -861 -2586 -1005 -146 -25 -380 -48 -389 -40 -8 9 40 226 77
            338 184 571 582 1111 1118 1516 179 136 309 215 525 323 278 138 483 208 805
            272 412 82 723 103 925 60z'
				/>
			</g>
		</svg>
	</SvgIcon>
);

export default CustomIcon;
