export const SIDEBAR_ITEMS = [
  {text: 'Home Page', name: 'Home', route: '/'},
  {text: 'Privacy Policy', name: 'PrivacyPolicy', route: '/privacy-policy' },
  {text: 'Contacts', name: 'Contacts', route: '/contacts'},
];

export const COMPANY_NAME = 'KiteLink Technologies';
export const COMPANY_NAME_LCC ='KiteLink Technologies LLC';
export const COMPANY_ADDRESS = '29 korp. b of. 105, vul. Obolonska, Kyiv, Kyiv, 04071, Ukraine';
export const COMPANY_PHONE = '+380 97 5792071';
export const COMPANY_EMAIL= 'support@kitelink.tech';
